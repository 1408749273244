
<template>
  <v-container>
    <v-dialog
        :value="popupClone"
        persistent
    >
        <v-card>
        <v-card-title>Pick Plans and Seller</v-card-title>
        <v-card-text>
            <v-list
                class="m-2"
            >
            <v-list-item v-for="(item, index) in plans" :key="index">
                <v-list-item-action>
                    <v-checkbox
                        v-model="selectedItems"
                        :value="item"
                        color="primary"
                    ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ item.planName }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list>
            <v-autocomplete
                v-model="selectedSeller"
                clearable
                outlined
                label="Seller"
                item-value="seller_id"
                item-text="username"
                :items="sellers"
            >

            </v-autocomplete>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="green"
                @click="doClone()"
                :disabled="selectedItems.length == 0 || selectedSeller == null || onProcess"
                :loading="onProcess"
            >
                Copy
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                @click="close()"
            >
                Close
            </v-btn>
        </v-card-actions>
        <Loading :status="onProcess" />
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Notice from "../helpers/notice";
import Loading from '../components/Loading.vue'
import { fetchData } from '../helpers';
export default {
  name: 'CloneSellerPlan',
  components: {
    Loading
  },
  props: {
    sellers: {
        type: Array,
        required: true,
        default: () => []
    }
  },
  data() {
    return {
        onProcess: false,
        plans: [],
        selectedItems: [],
        selectedSeller: null,
        popupClone: false,
    }
  },
  methods: {
    doClone(){
        this.onProcess = true;
        fetchData(`${this.$API}/api/Seller/clone-plan`, {
            seller_id: this.selectedSeller,
            plan_id: this.selectedItems.map(item => item.planId)
        }).then(res => {
            if(res.success == true){
                Notice.success({message:'Successfully cloned'});
                this.close();
            }else{
                let message = (res.msg) ? res.msg:"Server Error !!!";
                Notice.error({message});s
            }
            this.$emit('cloned');
        }).catch(err => {
            Notice.error({message: err.message});
        }).finally(()=>{
            this.onProcess = false;
        })
    },
    close(){
        this.popupClone = false;
        this.selectedItems = [];
        this.selectedSeller = null;
        this.plans = [];
    },
    open(plans) {
        this.plans = plans;
        this.popupClone = true
    }
    },
  watch: {
    selectedItems(val) {
      this.$emit('selected-items-changed', val)
    }
  }
}
</script>
